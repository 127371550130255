import React, { useState, useEffect } from "react"

import Header from "../components/header"
import Nav from "../components/nav"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Transition from "../utilities/transition"

const Layout = ({ children, location }) => {
  const [state, setState] = useState({
    active: false,
    animate: false,
    mobile: false,
  })

  const toggleMenu = () => {
    setState((prevState) => ({
      ...prevState,
      active: !state.active,
      animate: !state.animate,
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        active: !state.active,
        animate: state.animate,
      }))
    }, 150)

    if (window.innerWidth < 640) {
      document.getElementsByTagName("body")[0].scrollIntoView()
    }

    if (!state.active) {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden")
      document
        .getElementsByTagName("body")[0]
        .classList.add("sm:overflow-visible")
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden")
      document
        .getElementsByTagName("body")[0]
        .classList.remove("sm:overflow-visible")
    }
  }

  const toggleMenuMobile = () => {
    if (window.innerWidth < 640) {
      setTimeout(() => {
        toggleMenu()
      }, 250)
    }
  }

  useEffect(() => {
    if (location.pathname === "/") {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          mobile: true,
        }))
      }, 250)
    } else {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          mobile: false,
        }))
      }, 250)
    }
  }, [location.pathname])

  return (
    <div
      className={`flex flex-wrap container mx-auto md:h-full ${
        state.mobile ? "h-full flex-col md:flex-row" : ""
      }`}
    >
      <aside
        className={`md:relative top-0 w-full md:w-4/12 xl:w-3/12 px-6 py-10 lg:p-10 bg-white ${
          state.active
            ? "active fixed sm:sticky z-50 h-screen sm:h-auto"
            : "inactive sticky"
        } ${state.animate ? "animate" : "no-animate"}`}
      >
        <div className="mobile-container overflow-hidden">
          <div className="header flex justify-between items-start sm:items-center">
            <Header />
            <button
              className="hamburger md:hidden py-6 sm:py-0"
              type="button"
              aria-label="hamburger"
              onClick={toggleMenu}
            >
              <span className="hamburger-container">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div
            className="nav w-full sm:w-136 md:w-full mt-8 sm:mt-4 md:mt-8 outline-hidden"
            role="link"
            tabIndex="0"
            onClick={toggleMenuMobile}
            onKeyUp={toggleMenuMobile}
          >
            <Nav />
          </div>
          <div className="contact block w-full sm:w-136 md:w-full mt-8 sm:mt-4 md:mt-8">
            <Contact />
          </div>
          <div className="footer mt-8 sm:mt-4 md:mt-8">
            <Footer />
          </div>
        </div>
        <span className="divider absolute right-0 bottom-0 md:top-0 w-full md:w-px h-px md:h-screen-half lg:mr-10 md:mt-10 bg-gradient-white-to-grey-to-white md:bg-gradient-grey-to-white"></span>
      </aside>
      <main
        className={`flex justify-center items-center overflow-auto w-full md:w-8/12 xl:w-9/12 ${
          state.mobile ? "flex-1 md:flex-initial" : ""
        }`}
      >
        <div className="flex-1">
          <div className="flex flex-wrap md:justify-center items-center md:h-screen">
            <div className="w-full md:w-auto px-6 py-10 lg:p-10">
              <Transition location={location}>{children}</Transition>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Layout
