import React from "react"

import { ReactComponent as Linkedin } from "../images/linkedin.svg"
import { ReactComponent as Github } from "../images/github.svg"
import { ReactComponent as Resume } from "../images/resume.svg"

import resume from "../downloads/nathan_kowalski_resume.pdf"

const Contact = () => (
  <div className="block sm:flex md:inline-flex flex-row md:flex-col justify-between items-center md:items-stretch">
    <div className="flex flex-row md:flex-col justify-between w-44 md:w-full">
      <a
        className="flex md:inline-flex justify-center items-center md:items-baseline w-12 md:w-full px-4 py-2 rounded-full md:rounded-sm bg-gray-300 hover:bg-gray-400"
        href="https://www.linkedin.com/in/kowalskinathan"
        aria-label="linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <Linkedin className="w-4 h-4 md:mr-2 fill-current" alt="linkedin" />
        <span className="hidden md:inline">View LinkedIn</span>
      </a>
      <a
        className="flex md:inline-flex justify-center items-center w-12 md:w-full md:mt-4 px-4 py-2 rounded-full md:rounded-sm bg-gray-300 hover:bg-gray-400"
        href="https://github.com/nathankowalski"
        aria-label="github"
        target="_blank"
        rel="noreferrer"
      >
        <Github className="w-4 h-4 md:mr-2 fill-current" alt="github" />
        <span className="hidden md:inline">View GitHub</span>
      </a>
      <a
        className="flex md:inline-flex justify-center items-center w-12 md:w-full md:mt-4 px-4 py-2 rounded-full md:rounded-sm bg-gray-300 hover:bg-gray-400"
        href={resume}
        aria-label="resume"
        target="_blank"
        rel="noreferrer"
      >
        <Resume className="w-4 h-4 md:mr-2 fill-current" alt="resume" />
        <span className="hidden md:inline">Download Resume</span>
      </a>
    </div>
    <span className="hidden sm:inline-block md:hidden">|</span>
    <div className="mt-4 sm:mt-0 md:mt-4">
      <a
        className="hover:text-blue-400 hover:underline"
        href="mailto:nathan.kowalski@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        nathan.kowalski@gmail.com
      </a>
    </div>
    <span className="hidden sm:inline-block md:hidden">|</span>
    <div className="mt-2 sm:mt-0 md:mt-2">
      <a
        className="hover:text-blue-400 hover:underline"
        href="tel:4155703663"
        target="_blank"
        rel="noreferrer"
      >
        415.570.3663
      </a>
    </div>
  </div>
)

export default Contact
